export default [
  {
    header: 'اصلی',
  },
  {
    title: 'dashboard',
    route: 'home',
    icon: 'PieChartIcon',
  },
  {
    header: 'خدمات',
  },
  {
    title: 'اقامتگاه ها',

    icon: 'CodepenIcon',
    children: [
      {
        title: 'لیست اقامتگاه',
        route: 'residences',
      },
/*      {
        title: 'جدیدترین اقامتگاه',
        route: 'residencesNew',
      },
      {
        title: 'آخرین آپدیت اقامتگاه',
        route: 'residencesUpdate',
      },*/
      {
        title: 'تقویم روزهای پیک',
        route: 'calender-peak',
      },
      {
        title: 'دسته بندی',
        route:'categories',
      },
      {
        title: 'واحدها',
        route:'units',
      },
      {
        title: 'ویژگی اقامتگاه',
        route:'properties',
      },
      {
        title: 'امکانات',
        route: 'packages',
      },
      {
        title: 'اقلام بهداشتی',
        route: 'healthItems',
      },
      {
        title: 'امکانات ایمنی',
        route: 'autoimmunePackages',
      },
      {
        title: 'قوانین لغو رزرو',
        route: 'rules',
      },
      {
        title: 'مقررات اقامتگاه',
        route: 'regulations',
      },
    ],
  },

/*  {
    title: 'تور ها',

    icon: 'CameraIcon',
    children: [
      {
        title: 'لیست تورها',
        route: 'tours',
      },
      {
        title: 'دسته بندی',
        route: 'toursCategories',
      },
      {
        title: 'گروه سنی',
        route: 'toursAges',
      },
      {
        title: 'قوانین لغو رزرو',
        route: 'toursRules',
      },
      {
        title: 'مقررات تور',
        route: 'toursRegulations',
      },
    ],
  },

  {
    title: 'تجربه ها',

    icon: 'UmbrellaIcon',
    children: [
      {
        title: 'لیست تجربه ها',
        route: 'events',
      },
      {
        title: 'دسته بندی',
        route: 'eventsCategories',
      },
      {
        title: 'گروه سنی',
        route: 'eventsAges',
      },
      {
        title: 'قوانین لغو رزرو',
        route: 'eventsRules',
      },
      {
        title: 'مقررات تور',
        route: 'eventsRegulations',
      },
    ],
  },

  {
    title: 'ترانسفر ها',

    icon: 'TruckIcon',
    children: [
      {
        title: 'لیست ترانسفر ها',
        route: 'cars',
      },
      {
        title: 'دسته بندی',
        route: 'carsCategories',
      },
      {
        title: 'قوانین لغو رزرو',
        route: 'carsRules',
      },
      {
        title: 'مقررات ترانسفر',
        route: 'carsRegulations',
      },
    ],
  },*/


  {
    header: 'درخواست های رزرو',
  },
  {
    title: 'درخواست رزرو ها',

    icon: 'AwardIcon',
    children: [
      {
        title: 'رزرو اقامتگاه',
        route: 'orderSpace',
      },
    ],
  },


 {
    header: 'رزور ها و گزارشات',
  },
  {
    title: 'رزرو های آنلاین',

    icon: 'FileTextIcon',
    children: [
      {
        title: 'رزرو اقامتگاه',
        route: 'reservationSpace',
      },

    ],
  },

  /*  {
      title: 'رزرو های آفلاین',

      icon: 'WifiOffIcon',
      children: [
        {
          title: 'رزرو اقامتگاه',
          route: 'offlineSpace',
        },
        {
          title: 'رزرو تجربه',
          route: 'offlineEvent',
        },
        {
          title: 'رزرو تور',
          route: 'offlineTour',
        },
        {
          title: 'رزرو ترانسفر',
          route: 'offlineCar',
        },
      ],
    },*/




  {
    header: 'مدیریت',
  },
  {
    title: 'کاربران',
    icon: 'UsersIcon',
    children: [
      {
        title: 'لیست کاربران',
        route: 'users',
      },
      {
        title: 'لیست کارمندان',
        route: 'clerks',
      },
      {
        title: 'ادمین ها',
        route: 'admins',
      },
      {
        title: 'میزبان ها',
        route: 'owners',
      },
      {
        title: 'مدیریت نقش ها',
        route: 'roles',
      },
    ],
  },

  {
    header: 'سایر خدمات',
  },
  {
    title: 'sms',
    icon: 'MailIcon',
    children: [
      {
        title: 'ارسال sms خدمات',
        route: 'adminSms',
      },
      {
        title: 'ارسال sms شخصی',
        route: 'smsMessage',
      },
    ],
  },

  {
    title: 'seo',
    icon: 'LayersIcon',
    children: [
      {
        title: 'خدمات سئو شهرها',
        route: 'seoCity',
      },
    ],
  },

/*  {
    title: 'کد تخفیف',
    icon: 'GiftIcon',
    children: [
      {
        title: 'کد تخفیف اقامتگاه',
        route: 'discountSpace',
      },
      {
        title: 'کد تخفیف تجربه',
        route: 'discountEvent',
      },
      {
        title: 'کد تخفیف تور',
        route: 'discountTour',
      },
      {
        title: 'کد تخفیف ترانسفر',
        route: 'discountCar',
      },
    ],
  },*/


  {
    header: 'سایر بخش ها',
  },
  {
    title: 'cities_provinces',
    icon: 'MapPinIcon',
    children: [
      {
        title: 'provinces',
        route: 'provinces',
      },
      {
        title: 'cities',
        route: 'cities',
      }
    ],
  },
/*
{
  title: 'کشور و پایتخت',
  icon: 'CompassIcon',
  children: [
    {
      title: 'کشور',
      route: 'countries',
    },
    {
      title: 'شهرها',
      route: 'capitals',
    }
  ],
},*/
  {
    title: 'sliderAndPoster',
    route: 'slider',
    icon: 'ImageIcon',
    children: [
      {
        title: 'slider',
        route: 'slider',
      },
      {
        title: 'poster',
        route: 'poster',
      }
    ],
  },

/*  {
    title: 'خدمات روم تور',
    route: 'service',
    icon: 'FileTextIcon',
  },
  {
    title: 'خبرنامه',
    route: 'newsletters',
    icon: 'FileTextIcon',
  },
  {
    title: 'تماس با ما',
    route: 'contactus',
    icon: 'FileTextIcon',
  },*/


  {
    header: 'دیدگاه ها و نظرات',
  },
  {
    title: 'گفت و گوها',

    icon: 'TwitchIcon',
    children: [
      {
        title: 'آیتم های دیدگاه',
        route: 'commentsItems',
      },
      {
        title: 'دیدگاه ها',
        route: 'allComments',
      },
    ],
  },




/*
  {
    header: ' تسویه حساب با میزبان',
  },
  {
    title: 'تسویه حساب میزبان',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'تسویه نشده  ',
        route: 'paymentNotHost',
      },
      {
        title: 'تسویه شده ',
        route: 'paymentHost',
      }
    ],
  },

  {
    title: 'رزور سایر خدمات',
    route: 'booking',
    icon: 'FileTextIcon',
  },



  {
    title: 'آمار و گزارشات',

    icon: 'BarChartIcon',
    children: [
      {
        title: 'آمار کلی ',
        route: 'statistics',
      },
      {
        title: 'آمار کاربران ',
        route: 'statisticsUsers',
      },
      {
        title: 'آمار اقامتگاه',
        route: 'statisticsResidences',
      },
      {
        title: 'آمار تجربه',
        route: 'statisticsExperiences',
      },
      {
        title: 'آمار تور',
        route: 'statisticsTours',
      },
      {
        title: 'آمار ترانسفر',
        route: 'statisticsCars',
      },
    ],
  },*/
 

]
